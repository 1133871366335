export const CoursesData = [
  'Terapia Manualna wg koncepcji Maitland,',
  'Diagnozowanie i leczenie zespołów bólowych kręgosłupa i kończyn metodą McKenziego - kurs A,',
  'PNF - kurs podstawowy 1 i 2,',
  'Kurs Funkcjonalnej Terapii Manualnej wg koncepcji Briana Mulligana - część A i B,',
  'Badanie i fizjoterapia funkcjonalna w uszkodzeniach stawu kolanowego - moduł I i II,',
  'Badanie i fizjoterapia funkcjonalna w uszkodzeniach stawu barkowego,',
  'Kliniczne warsztaty szkoleniowe dla fizjoterapeutów - staw ramienny,',
  'Taping Medyczny,',
  'Taping Sportowy,',
  'Kurs stabilizacji centralnej, stawów obwodowych i stawu skroniowo-żuchwowego - moduł I, II i III,',
  'Instruktor Pilates (Metoda Pilates - praca na matach, Metoda Pilates - Senior),',
  'Kurs ESP - Muscle Balance and Sport Training,',
  'Nowoczesne formy masażu i inne techniki wykorzystywane w fizjoterapii (masaż poprzeczny, funkcyjny, tkanek głębokich),',
  'Masaż w sporcie,',
  'Wprowadzenie do metody Fascial Distortion Model,',
  'Anatomy Trains - moduł I,',
  'Fizjoterapia w geriatrii,',
  'Zintegrowane metody fizjoterapii',
  'Nowatorskie metody planowania i oceny korekcji paluchów koślawych na podstawie badań tomograficznych i funkcjonalnych,',
  'Kolano dziecięce - staw rzepkowo-udowy,',
  'Etapy rehabilitacji u pacjentów z zaburzeniami równowagi przy wykorzystaniu platform diagnostyczno-pomiarowych,',
  'Osteoporoza - choroba cywilizacyjna XXI wieku - aktualne aspekty profilaktyki, leczenia i rehabilitacji,',
  'Urazy w sportach zimowych,',
  'Praktyczne aspekty wykorzystania fali uderzeniowej w fizjoterapii,',
  'Wprowadzenie do metody  NEURAC 1 i wykorzystanie aparatu REDCORD w rehabilitacji medycznej,',
  'Celowana termiczna stymulacja tkanek w terapii układu ruchu,',
  'Podstawy obsługi i pracy z aparatem: terapia ultradźwiękowa, elektroterapia, laseroterapia , magnetoterapia, fala uderzeniowa i drenaż limfatyczny,',
  'Certyfikat z obsługi i bezpieczeństwa pracy na urządzeniach: VECTIS (urządzenie do ćwiczeń stawu barkowego z oporem elastycznym), TELKO (urządzenie do ćwiczeń kończyn dolnych), JUPITER (fotel do ćwiczeń oporowych kończyny dolnej)',
]
